<template>
    <select name="service_id" v-model="selectedService" @change="changeService">
        <option value="">Selecione um serviço</option>
        <option v-for="service in services" :key="service.id" :value="service">{{ service.name }}</option>
    </select>
</template>

<script>
export default {
    props: {
        modelValue: {
            type: [String, Object, Number],
            default: '',
        },
        exludeServicesId: {
            type: Array,
            default: [],
            required: false,
        }
    },
    watch: {
        modelValue(val) {
            if (val === null || val === undefined || val === '') {
                this.selectedService = '';
            }
        },
        exludeServicesId(val) {
            if (val.length > 0) {
                this.services = this.allServices.filter(service => !val.includes(service.id));
            }
        }
    },
    data() {
        return {
            selectedService: '',
            services: [],
            allServices: [],
        };
    },
    methods: {
        changeService() {
            this.$emit('update:modelValue', this.selectedService);
        },
        async getServices() {
            window.$('.loader').fadeIn();
            const route = window.$('.service-route').val();

            axios.get(route + '?jsonType=true&active=1').then(response => {
                window.$('.loader').fadeOut();
                this.services = response.data;
                this.allServices = response.data;
                this.services = this.allServices.filter(service => !this.exludeServicesId.includes(service.id));
            }).catch(error => {
                window.$('.loader').fadeOut();
                console.log(error);
            });
        }
    },
    async mounted() {
        await this.getServices();
    }
};
</script>